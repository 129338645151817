<template>
  <!-- 底板布局列表 -->
  <div class="app-container">
    <avue-crud
      ref="crud"
      class="flex-one-page"
      :option="tableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      :resetMergeData="resetMergeData"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #menuLeft>
        <AddCustomLayoutBtn v-if="permissionsList.hasAddCustomLayoutBtn" />
      </template>

      <template #create_timeSearchForm="{ form }">
        <SaleDateTime :time.sync="form['create_time']" @change="searchChange"></SaleDateTime>
      </template>

      <template #displayImagePath="{ row }">
        <DefaultImg :src="row.displayImagePath" />
      </template>

      <template #menu="{ row }">
        <AbleCustomLayoutBtn
          v-if="permissionsList.hasAbleCustomLayoutBtn"
          class="line"
          :data="row"
          :sup_this="sup_this"
        />
        <EditCustomLayoutBtn v-if="permissionsList.hasEditCustomLayoutBtn" class="line" :data="row" />
      </template>
    </avue-crud>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import SaleDateTime from '@/components/saleDateTime'

import DefaultImg from '@/views/components/defaultImg'

import AbleCustomLayoutBtn from './module/ableCustomLayoutBtn'
import AddCustomLayoutBtn from './module/addCustomLayoutBtn'
import EditCustomLayoutBtn from './module/editCustomLayoutBtn'

import { tableOption } from './const'

import { customLayoutList as getList } from '@/api/product/customFloorApi'
import { checkPermission } from '@/utils'

export default {
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],

  components: {
    SaleDateTime,

    DefaultImg,

    AbleCustomLayoutBtn,
    AddCustomLayoutBtn,
    EditCustomLayoutBtn
  },

  data() {
    return {
      resetMergeData: {
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      },
      sup_this: this
    }
  },

  computed: {
    permissionsList() {
      return {
        hasAddCustomLayoutBtn: checkPermission(['externaladmin:user:customFloorLayout:add']),
        hasAbleCustomLayoutBtn: checkPermission(['externaladmin:user:customFloorLayout:able']),
        hasEditCustomLayoutBtn: checkPermission(['externaladmin:user:customFloorLayout:edit'])
      }
    }
  },

  methods: {
    beforeInit() {
      this.tableData = []
    }
  }
}
</script>
